<template>
    <div class="devClass" v-if="dev">
      <div class="devClassTxt">Staging Site</div>
    </div>
    <div class="devClassLong" v-if="dev"></div>
    <router-view/>
</template>

<script>

import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"

export default {
  name: "App",
  data() {
    return {
      dev: false
    }
  },
  methods: {
    isDev() {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
        return true
      }
      return false
    },
  },
  mounted() {
    console.log('env', process.env.NODE_ENV)
    this.dev = this.isDev()
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");

.devClassTxt {
  position: relative;
  top: -5px;
}

.devClass {
  position: absolute;
  text-align: center;
  font-weight: bold;
  top: 0;
  z-index: 10000;
  width: 100%;
  background-color: red;
  height: 15px;
  font-size: 15px;
  color: white;
}

.devClassLong {
  position: absolute;
  top: 0;
  z-index: 10000;
  width: 10px;
  background-color: red;
  height: 100%;
  font-size: 15px;
  color: white;
}
</style>
