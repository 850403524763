<template>
  <div>
    <div class="card" v-if="!filter">
      <div class="card-header">
        <h2 class="card-title">Mass Payment Tool</h2>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="csvFile">Update frametracker products</label><br>
              <input type="file" id="csvFile" accept=".csv" @change="handleFileUpload" /><br /><br />
              <input type="hidden" id="useremail" v-model="userEmail" />
              <a href="#" style="color:blue;" @click.prevent="downloadFT_csv">Download Template</a>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer"></div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import { useToast } from 'vue-toastification'
import $ from 'jquery'
import {useStore} from "vuex";

export default {
  name: "UpdateProducts",
  setup() {
    const toast = useToast()
    const store = useStore()
    const UserInfo = computed(() => store.getters['userAccess/user'])

    const userEmail = ref(UserInfo.value.email)

    const csvData = ref([
      ['1100','1.2','1','1','2021-01-01','1','1','1','1','1','1','1','1','1','1','1','1','1','1','1','1','valid vendor num','manuf code'],
    ])

    const downloadFT_csv = () => {
      let csv = 'product,cost,landedcost,prevcost,changedate,length,chop,join,exact,glass1,glass2,glass3,glass4,mat1,mat2,mat3,mat4,sample,readymade,hardware,wastefactor,vendor,manufcode\n'

      const dta = {}
      const settings = {
        url: "https://ampfapi.ampfframes.com/index.php?ver=ver1&proc=basic&ukey=12345&runFunc=vendorList",
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(dta)
      }

      $.ajax(settings).done(function (response) {
        csvData.value.forEach(row => {
          csv += row.join(',') + '\n'
        })

        csv += "\n\n\n"

        if (response?.returnData?.vendors) {
          const vendors = response.returnData.vendors
          Object.values(vendors).forEach(vendor => {
            csv += `${vendor.Vendnum},${vendor.Company}\n`
          })
        }

        const hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'frametrackerproduct.csv'
        hiddenElement.click()

      }).fail(function (xhr) {
        console.error(xhr)
        toast.error("Failed to download vendor list.")
      })
    }

    const handleFileUpload = (event) => {
      const file = event.target.files[0]
      if (!file) return

      const textType = /text.*/;
      const textTypemS = /ms-excel/;

      if (!(file.type.match(textType) || file.type.match(textTypemS))) {
        toast.error('Invalid file format')
        event.target.value = ''
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        const base64Data = btoa(reader.result)
        const dta = {
          data: base64Data,
          useremail: userEmail.value
        }

        const settings = {
          url: "https://ampfapi.ampfframes.com/index.php?ver=ver1&proc=basic&ukey=12345&runFunc=updateFrames&xcode=fdjaflk@$%23",
          method: "POST",
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify(dta)
        }

        $.ajax(settings).done(function (response) {
          console.log(response)
          event.target.value = ''
          toast.success("File processed successfully")
        }).fail(function (xhr) {
          console.error(xhr)
          toast.error("File upload failed")
        })
      }

      reader.readAsText(file)
    }

    return {
      downloadFT_csv,
      handleFileUpload,
      csvData,
      userEmail,
      toast
    }
  }
}
</script>
